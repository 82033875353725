import React from "react";
import { Link } from "react-router-dom";
function Packages(props) {
  const { title, subtitle, points } = props;
  return (
    <div className="package_card">
      <div className="package_card_header">
        <h3 className="package_title">{title}</h3>
        <p className="package_subtitle">{subtitle}</p>
      </div>
      <div className="packags_card_body">
        <ul className="package_list">
          <ol>
            {points.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ol>
        </ul>
      </div>
      <div className="packages_card_footer">
        <Link to="/growth-accelerator">
          <button className="cta reqBtn">Request Quote
          <img
                          src={require("..//assets/images/arrow.png")}
                          alt="arrow"
                        /></button>
        </Link>
      </div>
    </div>
  );
}

export default Packages;
