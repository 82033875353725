import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function Newsletter() {
  var [email, setEmail] = useState("");
  var [fname, setfname] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [inputError, setInputError] = useState(false);
  const navigate = useNavigate();
  const validateEmail = (input) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newFormErrors = {};

    if (!email) {
      newFormErrors.email = "Email is required.";
    } else if (!validateEmail(email)) {
      newFormErrors.email = "Invalid email format.";
    }

    setInputError(Object.keys(newFormErrors).length > 0);

    if (Object.keys(newFormErrors).length > 0) {
      console.log(newFormErrors);
      return;
    }

    try {
      setShowPopup(true);
      document.body.style.overflow = "hidden";
      const response = await fetch("/newsletter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fname: fname,
          email: email,
        }),
      });

      
      const data = await response.json();

      if (data.sent) {
        setEmail("");
        setfname("");
        setEmailSent(true);
          setTimeout(() => {
            setShowPopup(false);
            setEmailSent(false);
            document.body.style.overflow = "";
            navigate("/");
            window.scrollTo({ top: 0, behavior: "smooth" });
          }, 3000);
      } else {
        console.log("Couldn't add email, try again later");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  return (
    <div id="newsletter_page">
      <div className="newsletter_wrapper">
        <div className="container">
          <Link to="/">
            <div className="newsletter_logo">
              <img src={require("..//assets/images/logo.png")} alt="logo" />
            </div>
          </Link>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6">
              <div className="page_content">
                <div className="page_form">
                  <h3 className="newsletter_title">
                    Join The Growth Accelerator Newsletter
                  </h3>
                  <p className="newsletter_description">
                    Valuable Freelancing advice delivered straight to your
                    inbox. Learn how to grow your freelance business,get more
                    clients & developer your skills as a web developer.All In
                    One Newsletter
                  </p>
                  <div className="newsletter_Form" id="frm_subscribe">
                    <form
                      onSubmit={handleSubmit}
                      name="subscribe"
                      className="d-flex justify-content-center"
                      noValidate
                    >
                      <input
                        type="text"
                        value={fname}
                        className="form-control fname_news"
                        onChange={(e) => setfname(e.target.value)}
                        name="subscribe_fname"
                        placeholder="Enter Your First Name"
                      />
                      <input
                        type="text"
                        value={email}
                        className={
                          "form-control email_news" +
                          (inputError ? " is-invalid" : "")
                        }
                        onChange={(e) => setEmail(e.target.value)}
                        name="subscribe_pemail"
                        placeholder="Enter Your Email"
                      />

                      {/* <input
                          type="hidden"
                          value=""
                          name="subscribe_pname"
                          id="subscribe_pname"
                        />
                        <div id="notification"></div> */}

                      <button type="submit" className="cta subscribeBtn">
                        Join The Newsletter
                      </button>
                    </form>
                    <p className="form_note">
                      We will make sure to only send you valuable content to
                      your inbox
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="values_list_div">
                  <ul className="values_list">
                    <li>
                      <img
                        src={require("..//assets/images/tick.png")}
                        alt="tick icon"
                      ></img>
                      <p>Get Valuable Content</p>
                    </li>
                    <li>
                      <img
                        src={require("..//assets/images/tick.png")}
                        alt="tick icon"
                      ></img>
                      <p>Level Up Your Skills</p>
                    </li>
                    <li>
                      <img
                        src={require("..//assets/images/tick.png")}
                        alt="tick icon"
                      ></img>
                      <p>Boost Your Online Presence</p>
                    </li>
                    <li>
                      <img
                        src={require("..//assets/images/tick.png")}
                        alt="tick icon"
                      ></img>
                      <p> A Community of Skilled Devs & designers</p>
                    </li>
                  </ul>
                </div> */}
        </div>
      </div>
      <div className="newsletter_footer">
        <p className="copyrights">2024 Growwithalaa.All Rights Reserved</p>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            {emailSent ? (
              <p>Thank You For Being A Valuable Member!</p>
            ) : (
              <p>Adding Your Email📤...</p>
            )}
          </div>
        </div>
      )}

    </div>
  );
}

export default Newsletter;
