import React from "react";

function Testimonial(props) {
  const { title, review, name, jobTitle } = props;
  return (
    <div className="testimonial_card">
      <div className="testimonial_card_content">
        <h4 className="testimonial_card_title">"{title}"</h4>
        <p className="testimonial_card_text">{review}</p>
      </div>
      <img src={require("..//assets/images/stars.png")} alt="Testimonial" className="testimonial_img" />
      <div className="closing_info">
        <h5 className="client_name">{name}</h5>
        <p className="client_position">{jobTitle}</p>
      </div>
    </div>
  );
}

export default Testimonial;
