import React from "react";

function Service(props) {
  const { icon, serviceName, serviceDescription } = props;
  return (
    <div className="service_card">
      <img src={icon} alt="icon" className="service_icon" />
      <h4 className="service_name">{serviceName}</h4>
      <p className="service_description">{serviceDescription}</p>
    </div>
  );
}

export default Service;
