import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function Header() {
  const [showNav, setShowNav] = useState(false);
  const location = useLocation();

  const handleScheduleClick = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/alaaezdine/30min",
      });
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const toggleNavbar = () => {
    setShowNav(!showNav);
  };

  const hideMenu = () => {
    setShowNav(false);
  };

  return (
    <div>
      <link
        href="https://assets.calendly.com/assets/external/widget.css"
        rel="stylesheet"
      />
      <div className="Header_page">
        <div className="container">
          <div className="nav_wrapper">
            <Link to="/">
              <div className="logo_div">
                <img src={require("..//assets/images/logo.png")} alt="logo" />
              </div>
            </Link>
            <div className={`nav_links ${showNav ? "show" : ""}`}>
              <ul className="nav_list">
                {location.pathname !== "/conversion-catalyst" && (
                  <Link to="/" className="nav_link" onClick={hideMenu}>
                    <li>Home</li>
                  </Link>
                )}
                {location.pathname !== "/conversion-catalyst" && (
                  <a href="#packages" className="nav_link" onClick={hideMenu}>
                    <li>Packages</li>
                  </a>
                )}
                {location.pathname === "/conversion-catalyst" ? (
                  <>
                    <a
                      href="#funnel_questions"
                      className="nav_link"
                      onClick={hideMenu}
                    >
                      <li>Work With Me</li>
                    </a>
                    {/* <Link to="/newsletter" className="nav_link" onClick={hideMenu}>
                      <li>Newsletter</li>
                    </Link> */}
                  </>
                ) : (
                  <Link
                    to="/growth-accelerator"
                    className="nav_link"
                    onClick={hideMenu}
                  >
                    <li>Work With Me</li>
                  </Link>
                )}
              </ul>
            </div>
            <div className="nav_cta">
              <Link>
                <button className="cta navbtn" onClick={handleScheduleClick}>
                  Book A Call
                </button>
              </Link>
              <div className="mobile_menu" onClick={toggleNavbar}>
                <img
                  src={
                    showNav
                      ? require("..//assets/images/close-icon.png")
                      : require("..//assets/images/ham_menu.png")
                  }
                  alt="Mobile Menu Icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
