import React,{useEffect} from "react";
import { Link } from "react-router-dom";
function Footer() {
  const handleScheduleClick = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/alaaezdine/30min",
      });
    }
  };
  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div id="footer">
      <div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="footer_slogan">
                Trust us to build your success online.
              </h2>
              <div className="footer_wrapper">
                <div className="logo_div">
                  <div className="logo_div_footer">
                    <img
                      src={require("..//assets/images/logo.png")}
                      alt="footer logo"
                    />
                  </div>
                </div>

                <div className="footer_links">
                  <ul className="footer_list">
                    <Link to="/" className="link_item">
                      <li>Home</li>
                    </Link>
                    <Link to="/newsletter" className="link_item">
                      <li>Newsletter</li>
                    </Link>
                    <Link to="/conversion-catalyst" className="link_item" onClick={handleLogoClick}>
                      <li>Conversion Catalyst Program</li>
                    </Link>
                    <Link to="/growth-accelerator" className="link_item">
                      <li>Contact Us</li>
                    </Link>
                    {/* <Link className="link_item">
                      <li>Privacy Policy</li>
                    </Link> */}
                  </ul>
                </div>

                <div className="footer_cta">
                    <button className="cta footerbtn" onClick={handleScheduleClick}>Book A Call </button>              
                </div>
              </div>
              <div className="social_icons">
                <a href="https://www.instagram.com/growwithalaa/">
                  <img
                    src={require("..//assets/images/insta.png")}
                    alt="insta logo"
                  />
                </a>
                <a href="https://www.linkedin.com/in/alaaabiezdine/">
                  <img
                    src={require("..//assets/images/linkedin.png")}
                    alt="linkedIn logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rights">
        
          <p className="copyrights">2024 GrowwIthalaa.All Rights Reserved</p>
        
      </div>
    </div>
  );
}

export default Footer;
