import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Service from "../components/Services";
import Packages from "../components/Packages";
import ProjectsSwiper from "../components/ProjectsSwiper";
import Testimonial from "../components/Testimonials";
import FAQCard from "../components/FAQ";
import Footer from "../components/Footer";
import Header from "../components/Header";
function Home() {
  const handleScheduleClick = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/alaaezdine/30min",
      });
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const package1 = [
    `Hosting`,
    `Domain`,
    `SSL`,
    `Custom & Responsive Design`,
    `Up to 4 Pages`,
    `Post-launch Support (30 days)`,
    `Google Indexing`,
    `Up to 2 rounds of revision`,
  ];
  const package2 = [
    `All features in Basic Package`,
    `Blog or News Post Integration`,
    `Analytics Integration`,
    `Content Management system`,
    `Google My Business Integration`,
    `Live Chat Integration`,
    `Extended Pages (up to 8)`,
    `Newsletter & Email Marketing`,
  ];
  const package3 = [
    `All features in Standard Package`,
    `Enhanced SEO (Premium level)`,
    `Priority Support`,
    `Extended Pages (10-15)`,
    `Post-launch Support (60 days)`,
    `Monthly Performance Reports`,
    `Advanced Security Features`,
    `Advanced Sales Funnels`,
  ];
  return (
    <div>
      <link
        href="https://assets.calendly.com/assets/external/widget.css"
        rel="stylesheet"
      />
      <Header />
      <div id="home_page">
        <div className="home_page_wrapper">
          <div className="hero_section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="hero_content">
                    <h1 className="hero_title">
                      Rapid,High-Converting Websites For Your Business!
                    </h1>
                    <p className="hero_description">
                      Boost your business with our unique website development
                      services. We make custom online solutions that power up
                      your online presence. We're great at making websites that
                      turn visitors into loyal customers.
                    </p>
                    <a href="#packages">
                      <button className="cta herobtn">
                        See Packages
                        <img
                          src={require("..//assets/images/arrow.png")}
                          alt="arrow"
                        />
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="full_line"></div>
          {/* call to action */}
          <div className="call_to_action_div">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <p className="cta_question">Want My Help?</p>
                </div>
                <div className="col-lg-6 waitlistcta">
                  <Link to="/growth-accelerator">
                    <button className="cta cta_divbtn">
                      Join The Waitlist
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="full_line"></div>

          {/* Services*/}
          <div className="services">
            <div className="container">
              <div className="row">
                <div className="services_content">
                  <h2 className="services_title">What Can I Offer</h2>
                  <p className="services_subtitle">
                    Check Out all the services we offer
                  </p>
                </div>
                <div className="col-lg-4">
                  <Service
                    icon={`${require("..//assets/images/webdesign_icon.png")}`}
                    serviceName="Web Design"
                    serviceDescription="Our designs are modern, high-quality, and user-friendly, reflecting your brand while engaging your audience effectively."
                  />
                </div>
                <div className="col-lg-4">
                  <Service
                    icon={`${require("..//assets/images/webdev_icon.png")}`}
                    serviceName="Web Development"
                    serviceDescription="We bring designs to life, ensuring functionality and interactivity tailored to your specific business needs, using expert coding and robust site architecture."
                  />
                </div>
                <div className="col-lg-4">
                  <Service
                    icon={`${require("..//assets/images/seo_icon.png")}`}
                    serviceName="SEO"
                    serviceDescription=" Improve your website's visibility organically. Our SEO service enriches content, refines keywords, and employs strategies to boost rankings and drive targeted traffic."
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="half_line"></div>

          {/* work process*/}
          <div className="work_process">
            <div className="container">
              <h3 className="work_process_title">Our Work Process</h3>
              <p className="work_process_subtitle">
                Check Out all the services we offer
              </p>
              <div className="row">
                <div className="col-lg-12">
                  <div className="process">
                    <div className="process_line"></div>
                    <div class="process_card">
                      <h4 class="process_name">Consultation</h4>
                      <div class="process_number_wrapper">
                        <div class="process_number">01</div>
                      </div>
                      <div className="details_wrapper">
                        <h4 class="process_name">Consultation</h4>
                        <p class="process_description">
                          We discuss goals, needs, and vision to shape a
                          tailored plan for your website's success.
                        </p>
                      </div>
                    </div>
                    <div class="process_card">
                      <h4 class="process_name">Development Phases</h4>
                      <div class="process_number_wrapper">
                        <div class="process_number">02</div>
                      </div>
                      <div className="details_wrapper">
                        <h4 class="process_name">Development Phases</h4>
                        <p class="process_description">
                          We start by crafting a unique & modern website that
                          aligns with your brand identity and goals.
                        </p>
                      </div>
                    </div>

                    <div class="process_card">
                      <h4 class="process_name">Website Launch</h4>
                      <div class="process_number_wrapper">
                        <div class="process_number">03</div>
                      </div>
                      <div className="details_wrapper">
                        <h4 class="process_name">Website Launch</h4>
                        <p class="process_description">
                          After careful testing, your website goes live,
                          ensuring a smooth transition and immediate
                          accessibility to your audience.
                        </p>
                      </div>
                    </div>
                    <div class="process_card">
                      <h4 class="process_name">Client Collaboration</h4>
                      <div class="process_number_wrapper">
                        <div class="process_number">04</div>
                      </div>
                      <div className="details_wrapper">
                        <h4 class="process_name">Client Collaboration</h4>
                        <p class="process_description">
                          Continuous communication and feedback integration for
                          ongoing site optimization and enhancements.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="half_line"></div>

          <div className="packages_div" id="packages">
            <div className="container">
              <div className="row">
                <div className="packages_content">
                  <h2 className="packages_title">What Will You Get</h2>
                  <p className="packages_subtitle">
                    If you decided to invest in your business and work with me
                  </p>
                </div>
                <div className="col-lg-4">
                  <Packages
                    title="Basic"
                    subtitle="StarterWeb"
                    points={package1}
                  />
                </div>
                <div className="col-lg-4">
                  <Packages
                    title="Standard"
                    subtitle="ProWeb"
                    points={package2}
                  />
                </div>
                <div className="col-lg-4">
                  <Packages
                    title="Premium"
                    subtitle="EliteWeb"
                    points={package3}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="package_card customized_package">
                    <div className="package_card_header">
                      <h3 className="package_title customized">
                        Looking For A Customized Package
                      </h3>
                      <p className="package_subtitle customized_p">
                        Schedule a call to discuss your specific requirements.
                      </p>
                    </div>
                    <Link>
                      <button
                        className="cta reqBtn"
                        onClick={handleScheduleClick}
                      >
                        Book A Call
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="half_line"></div>

          <div className="projects_div">
            <div className="container">
              <div className="row">
                <div className="projects_content">
                  <h2 className="projects_title">Projects</h2>
                  <p className="projects_subtitle">
                    Check out some of the previous work
                  </p>
                </div>
                <div className="col-lg-12">
                  <ProjectsSwiper />
                </div>
              </div>
            </div>
          </div>

          <div className="half_line"></div>

          <div className="testimonials_div">
            <div className="container">
              <div className="row">
                <div className="testimonials_content">
                  <h2 className="testimonials_title">Testimonials</h2>
                  <p className="testimonials_subtitle">
                    What do people say about me and my work
                  </p>
                </div>
                <div className="col-lg-4">
                  <Testimonial
                    title="Highly Recommended"
                    review="Previously, I didn't have a website that met my expectations until I collaborated with Alaa. Their creation of a professional website for my agency exceeded my expectations,delivering exceptional results. I highly recommend starting to work with Alaa."
                    name="Rachel Al Houjairy"
                    jobTitle="Founder of Verse Media"
                  />
                </div>
                <div className="col-lg-4">
                  <Testimonial
                    title="Amazing Work"
                    review="Working with Alaa was amazing and beyond expectations; the designs were excellent, all looking flawless. All of that was achieved for amazing prices."
                    name="Nadeem Ghaddar"
                    jobTitle="Founder Of Kissable"
                  />
                </div>
                <div className="col-lg-4">
                  <Testimonial
                    title="Impressive Results"
                    review="Working with Alaa was a fantastic experience,he was able to deliver what he promised to on time and the end results were great. On Top of that I was amazed with how fast he responeds to all our questions.I highly recommend working with him."
                    name="Wael Hammad"
                    jobTitle="Founder Of Hiking Lebanon"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="half_line"></div>

          <div className="blogs_div">
            <div className="container">
              <div className="row">
                <div className="blogs_content">
                  <h2 className="blogs_title">Testimonials</h2>
                  <p className="blogs_subtitle">
                    What do people say about me and my work
                  </p>
                </div>
                <div className="col-lg-4">
                  <Blog
                    title="How to get your first client in 2024"
                    thumbnail={`${require("..//assets/images/blogThumbnail.png")}`}
                    author="Alaa Abi Ezzddine"
                    date="January,21,2024"
                  />
                </div>
                <div className="col-lg-4">
                  <Blog
                    title="How to get your first client in 2024"
                    thumbnail={`${require("..//assets/images/blogThumbnail.png")}`}
                    author="Alaa Abi Ezzddine"
                    date="January,21,2024"
                  />
                </div>
                <div className="col-lg-4">
                  <Blog
                    title="How to get your first client in 2024"
                    thumbnail={`${require("..//assets/images/blogThumbnail.png")}`}
                    author="Alaa Abi Ezzddine"
                    date="January,21,2024"
                  />
                </div>
              </div>
            </div>
          </div> */}

          <div className="half_line"></div>

          <div className="faq_div">
            <div className="container">
              <div className="row">
                <div className="faq_content">
                  <h2 className="faq_title">Frequently asked questions</h2>
                  <p className="faq_subtitle">
                    Here's the answer to the most common asked questions about
                    the web design & development process
                  </p>
                </div>
                <div className="col-lg-8 m-auto">
                  <div className="faq_questions">
                    <FAQCard
                      title="What factors influence the final pricing of a website?"
                      content="We consider various elements such as complexity, features, customization, and additional services
                    required. Each project is unique, influencing the final pricing structure"
                    />

                    <FAQCard
                      title="Do you offer maintenance & support after the website launch?"
                      content="Yes, we provide ongoing maintenance and support services through different retainer packages to
                    ensure your website remains secure, up-to-date, and functional."
                    />

                    <FAQCard
                      title="Can I request changes or additions to the initial project scope?"
                      content="We understand that requirements may evolve. We accommodate changes within the project scope,
                    and any additional work is discussed and priced accordingly"
                    />

                    <FAQCard
                      title="What is the typical timeline for completing a website project?"
                      content="Timelines vary based on project complexity. We provide estimated timelines during the consultation
                    phase and maintain communication throughout the development process to ensure timely delivery."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="half_line"></div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Home;
