import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const quiz = {
  questions: [
    {
      question: "Do you currently have a website?",
      choices: ["Yes, but it's outdated", "No, I don't have a website"],
      type: "MCQs",
    },
    {
      question: "Where is your business based?",
      type: "MCQs",
      choices: ["Lebanon", "USA", "UK", "Canada", "GCC", "Other"],
    },
    {
      question: "What type of business do you have?",
      choices: ["Service", "E-commerce", "Software", "Others"],
      type: "MCQs",
    },
    {
      question: "Please describe your business in 1- 3 sentences MAX.",
      type: "Textarea",
    },
    {
      question: "What is your estimated budget for the website?",
      choices: [
        "Up to $500",
        "$500-$800",
        "$800-$1,200",
        "$1,200-$2,000",
        "$2,000-$3,000",
        "$3,000+",
      ],
      type: "MCQs",
    },
    {
      question:
        "Are there any design examples of websites you admire? Please provide links or descriptions.",
      type: "Textarea",
    },
    {
      question: "Where did you FIRST hear of Us?",
      choices: [
        "Instagram",
        "Facebook",
        "LinkedIn",
        "From A Friend",
        "Google",
        "Others",
      ],
      type: "MCQs",
    },
    {
      question: "What's Your Best Email?",
      type: "Input",
    },
  ],
};

function GrowthAccelerator() {
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [firstQuestionAnswer, setFirstQuestionAnswer] = useState(0);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const navigate = useNavigate();
  const [answers, setAnswers] = useState(() => {
    const storedAnswers = localStorage.getItem("growthAccAnswers");
    return storedAnswers
      ? JSON.parse(storedAnswers)
      : Array(quiz.questions.length).fill(null);
  });
  const [textAreaAnswer, setTextAreaAnswer] = useState("");
  const [InputAnswer, setInputAnswer] = useState("");
  const [isFinished, setIsFinished] = useState(false);
  const { questions } = quiz;
  const { question, choices, type, options } = questions[activeQuestion];

  useEffect(() => {
    localStorage.setItem("growthAccAnswers", JSON.stringify(answers));
  }, [answers]);

  const onTextAreaChange = (e) => {
    setTextAreaAnswer(e.target.value);
    onAnswerSelected(0);
  };

  const onInputChange = (e) => {
    setInputAnswer(e.target.value);
    onAnswerSelected(0);
  };

  const onClickPrevious = () => {
    if (activeQuestion !== 0) {
      setActiveQuestion((prev) => prev - 1);
      setSelectedAnswerIndex(null);
      setTextAreaAnswer("");
      setInputAnswer("");
    }
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };
  const renderInput = () => {
    if (question === "Which Best Describes You?") {
      if (type === "MCQs") {
        return (
          <ul className="card-list">
            {choices.map((answer, index) => (
              <li
                onClick={() => {
                  onAnswerSelected(index);
                  setFirstQuestionAnswer(index);
                }}
                key={answer}
                className={
                  selectedAnswerIndex === index ? "selected-answer" : null
                }
              >
                <div className="radioBtn"></div>
                {answer}
              </li>
            ))}
          </ul>
        );
      }
    }

    if (type === "MCQs") {
      return (
        <ul className="card-list">
          {choices.map((answer, index) => (
            <li
              onClick={() => onAnswerSelected(index)}
              key={answer}
              className={
                selectedAnswerIndex === index ? "selected-answer" : null
              }
            >
              <div className="radioBtn"></div>
              {answer}
            </li>
          ))}
        </ul>
      );
    } else if (type === "Textarea") {
      return (
        <textarea
          className="text_area"
          placeholder="Write A Short Description here"
          rows="6"
          cols="50"
          onChange={(e) => onTextAreaChange(e)}
        />
      );
    } else if (type === "Input") {
      return (
        <input
          type="email"
          className="email_field"
          placeholder="Enter your email"
          onChange={(e) => onInputChange(e)}
          required
        />
      );
    }
  };

  const onClickNext = () => {
    if (
      type === "Textarea" ||
      type === "Input" ||
      selectedAnswerIndex !== null
    ) {
      if (type === "Textarea") {
        setTextAreaAnswer("");
        const newAnswers = [...answers];
        newAnswers[activeQuestion] = textAreaAnswer;
        setAnswers(newAnswers);
        setTextAreaAnswer("");
      } else if (type === "Input") {
        if (!isValidEmail(InputAnswer)) {
          alert("Please enter a valid email address.");
          return;
        }

        const newAnswers = [...answers];
        newAnswers[activeQuestion] = InputAnswer;
        setAnswers(newAnswers);
      } else {
        const newAnswers = [...answers];
        newAnswers[activeQuestion] =
          type === "Select"
            ? options[selectedAnswerIndex]
            : choices[selectedAnswerIndex];
        setAnswers(newAnswers);
      }

      setSelectedAnswerIndex(null);
      setTextAreaAnswer("");
      setInputAnswer("");

      if (activeQuestion === questions.length - 1) {
        setIsFinished(true);
      } else if (activeQuestion !== questions.length - 1) {
        setActiveQuestion((prev) => prev + 1);
      }
    }
  };
  const onFinish = () => {
    alert("Thank You We Received Your Request and will get back to you soon!");
    
    try {
      fetch("/growth-accelerator", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ answers }),
      })
        .then((response) => response.json())
        .then((data) => {
          navigate("/");
          console.log("Answers saved successfully:", data);
        });
    } catch (error) {
      console.error("Error saving answers:", error);
    }
    setActiveQuestion(0);
    setAnswers(Array(quiz.questions.length).fill(null));
    setIsFinished(false);
  };

  const onAnswerSelected = (index) => {
    setSelectedAnswerIndex(index);
  };

  // const addLeadingZero = (number) => (number > 9 ? number : `0${number}`);

  return (
    <div className="growth-accelerator">
      <div className="container">
        <Link to="/">
          <div className="growth_logo">
            <img src={require("..//assets/images/logo.png")} alt="logo" />
          </div>
        </Link>
        <div className="row">
          <div className="col-lg-8 flex-column d-flex justify-content-center align-items-center mx-auto">
            <h2 className="growth-accelerator-title">
              DO YOU WANT TO GROW <span>YOUR BUSINESS ONLINE?</span>
            </h2>

            <div className="growth-card">
              <div className="card-content">
                <h4 className="card_question">{question}</h4>
                {renderInput()}
                <div className="card-bottom flex-right">
                  {activeQuestion >= 0 && (
                    <button
                      disabled={activeQuestion === 0}
                      onClick={onClickPrevious}
                      className="cardBtn prevBtn"
                    >
                      Previous
                    </button>
                  )}
                  {isFinished ? (
                    <button className="cardBtn" onClick={onFinish}>
                      Finish
                    </button>
                  ) : (
                    <button
                      onClick={onClickNext}
                      className="cardBtn nextBtn"
                      disabled={selectedAnswerIndex === null}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GrowthAccelerator;
