import React from "react";
import CaseStudySwiper from "./CaseStudySwiper";
import { Link } from "react-router-dom";
function CaseStudy(props) {
  //  const project = props.location && props.location.state ? props.location.state.project1 : null;

  //   const { subtitle, title, description, detail, mockup } = project;

  return (
    <div id="case_study">
      <div className="case_study_wrapper">
        <div className="case_study_hero">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="casestudy_hero_content">
                  <div className="casestudy_subtitle">
                    <h1>Digital Marketing Agency</h1>
                  </div>
                  <h2 className="casestudy_hero_title">
                    A unique and modern Digital Agency Website
                  </h2>
                  <p className="casestudy_hero_description">
                    Majestic Media is a Lebanese Based Digital Agency. The
                    Website’s goal was to showcase the agency services and
                    projects to its clients in a clear & user-friendly way.
                  </p>
                  <div className="casestudy_details">
                    <div className="detail">
                      <p>Built In 1 Week</p>
                    </div>
                    <div className="detail">
                      <img
                        src={require("..//assets/images/visual_studio.png")}
                        alt="visual studio"
                      />
                    </div>
                    <div className="detail">
                      <img
                        src={require("..//assets/images/figma.png")}
                        alt="figma"
                      />
                    </div>
                  </div>
                  <a href="#packages">
                    <button className="cta casestudy_herobtn">
                      I want a website like this
                    </button>
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mockup_img">
                  <img
                    src={require("..//assets/images/majesticmedia_mockup.png")}
                    alt="mockup"
                    className="mockup"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="casestudy_body">
          <div className="preview_swiper">
            <CaseStudySwiper />
          </div>

          <div className="casestudy_process">
            <h3 className="process_title">Our Work Process </h3>

            <div className="process_steps">
              <div className="process_step">
                <div className="process_stepImg">
                  <img
                    src={require("..//assets/images/bubble.png")}
                    alt="Bubble Icon"
                  />
                </div>
                <p className="process_steptitle">Communicate</p>
              </div>

              <div className="process_step">
                <div className="process_stepImg">
                  <img
                    src={require("..//assets/images/brain.png")}
                    alt="Brain Icon"
                  />
                </div>
                <p className="process_steptitle">Ideate</p>
              </div>

              <div className="process_step">
                <div className="process_stepImg">
                  <img
                    src={require("..//assets/images/design.png")}
                    alt="Design Icon"
                  />
                </div>
                <p className="process_steptitle">Design</p>
              </div>

              <div className="process_step">
                <div className="process_stepImg">
                  <img
                    src={require("..//assets/images/developer.png")}
                    alt="Develope Icon"
                  />
                </div>
                <p className="process_steptitle">Develope</p>
              </div>
            </div>
          </div>

          <div className="project_description">
            <div className="contanier">
              <div className="row">
                <div className="col-lg-8">
                  <div className="project_description_info">
                    <h4 className="project_info_title">
                      Creating Modern & High Converting Websites
                    </h4>
                    <p className="project_info_paragraph">
                      Our designs are modern, high-quality, and user-friendly,
                      reflecting your brand while engaging your audience
                      effectively.
                      <br />
                      <br />
                      Our designs are modern, high-quality, and user-friendly,
                      reflecting your brand while engaging your audience
                      effectively.Our designs are modern, high-quality, and
                      user-friendly, reflecting your brand while engaging your
                      audience effectively.
                    </p>
                    <div className="casestudy_details">
                    <div className="detail">
                      <p>SEO Friendly ✅</p>
                    </div>
                    <div className="detail middle">
                    <p>User Friendly ✅</p>
                    </div>
                    <div className="detail">
                    <p>Fast Loading Speed ✅</p>
                    </div>
                  </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="project_cta">
                    <div className="project_cta_logo">
                      <img
                        src={require("..//assets/images/logo.png")}
                        alt="logo"
                      />
                    </div>
                    <p className="project_cta_paragraph">
                    Let’s talk about your website project!
                    </p>
                    <Link to="/growth-catalyst">
                      <button className="ctaBtn project_ctaBtn">Book A Free Call</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="project_features">
            <div className="contanier">
              <div className="row">
                <div className="col-lg-6">
                  <ul className="features_list">
                    <li>
                      <img
                        src={require("..//assets/images/purplearrow.png")}
                        alt="Arrow"
                      />
                      <p>Modern & User Friendly Design</p>
                    </li>
                    <li>
                      <img
                        src={require("..//assets/images/purplearrow.png")}
                        alt="Arrow"
                      />
                      <p>Modern & User Friendly Design</p>
                    </li>
                    <li>
                      <img
                        src={require("..//assets/images/purplearrow.png")}
                        alt="Arrow"
                      />
                      <p>Modern & User Friendly Design</p>
                    </li>
                    <li>
                      <img
                        src={require("..//assets/images/purplearrow.png")}
                        alt="Arrow"
                      />
                      <p>Modern & User Friendly Design</p>
                    </li>
                    <li>
                      <img
                        src={require("..//assets/images/purplearrow.png")}
                        alt="Arrow"
                      />
                      <p>Modern & User Friendly Design</p>
                    </li>
                    <li>
                      <img
                        src={require("..//assets/images/purplearrow.png")}
                        alt="Arrow"
                      />
                      <p>Modern & User Friendly Design</p>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <div className="mockup_image">
                    <img
                      src={require("..//assets/images/majesticmedia_mockup1.png")}
                      alt="Mockup"
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseStudy;
