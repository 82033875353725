import React, { useState } from "react";
import { Navigation, Pagination, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function ProjectsSwiper() {
  // const project1 = {
  //   id: 1,
  //   name: "Majestic Media",
  //   subtitle: "Digital Agency Website",
  //   title: "A unique and modern Digital Agency Website",
  //   description:
  //     "Majestic Media is a Lebanese Based Digital Agency. The Website’s goal was to showcase the agency services and projects to its clients in a clear & user-friendly way.",
  //   detail: "Built In 1 Week",
  //   mockup: require("..//assets/images/mockup1.png"),
  // };
  // const [projects] = useState([
  //   {
  //     id: 1,
  //     name: "Majestic Media",
  //     subtitle: "Digital Agency Website",
  //     title: "A unique and modern Digital Agency Website",
  //     description:
  //       "Majestic Media is a Lebanese Based Digital Agency. The Website’s goal was to showcase the agency services and projects to its clients in a clear & user-friendly way.",
  //     detail: "Built In 1 Week",
  //     mockup: require("..//assets/images/mockup1.png"),
  //   },
  // ]);

  return (
    <div className="projects_swiper">
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        modules={[Navigation, Pagination, A11y]}
        navigation
      >
        {/* {projects.map((project) => ( */}
        <SwiperSlide>
          {" "}
          <div className="row project">
            <div className="col-lg-6">
              <div className="project_image">
                <img
                  src={require("..//assets/images/hadisbookstore.PNG")}
                  className="project_img"
                  alt="projectImage"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="project_description">
                <h4 className="project_name">Hadi's Bookstore</h4>
                <p className="about_project_description">
                  Fully responsive Landing Page for a Library.
                </p>
                <p className="about_project_description">
                  A high-converting, user-friendly landing page designed
                  specifically for a library. This dynamic website is crafted to
                  boost membership sales by introducing the innovative new model
                  adopted by the library.
                </p>
                {/* <Link to="/case-study"> */}
                <a href="https://www.hadisbookstore.com">
                  <button className="cta project_link">
                    View Website
                    <img
                      src={require("..//assets/images/arrow.png")}
                      alt="arrow"
                    />
                  </button>
                </a>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="row project">
            <div className="col-lg-6">
              <div className="project_image">
                <img
                  src={require("..//assets/images/konnectMediaWeb.PNG")}
                  className="project_img"
                  alt="projectImage"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="project_description">
                <h4 className="project_name">Konnect Media</h4>
                <p className="about_project_description">
                  Full responsive,user-friendly and easy to navigate website for
                  a digital agency based In Lebanon.
                </p>
                <p className="about_project_description">
                  The Website showcases the agency work,services and all what
                  you need to know about.
                </p>

                {/* <Link to={{ pathname: "/case-study", state: project1 }}> */}
                <a href="https://www.konnectmedia.co">
                  <button className="cta project_link">
                    View Website
                    <img
                      src={require("..//assets/images/arrow.png")}
                      alt="arrow"
                    />
                  </button>
                </a>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
        {/* ))} */}
        <SwiperSlide>
          {" "}
          <div className="row project">
            <div className="col-lg-6">
              <div className="project_image">
                <img
                  src={require("..//assets/images/smartvisionlb.PNG")}
                  className="project_img"
                  alt="projectImage"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="project_description">
                <h4 className="project_name">Smart Vision</h4>
                <p className="about_project_description">
                  Fully responsive website for a digital agency.
                </p>
                <p className="about_project_description">
                  With intuitive navigation at its core, this site showcases the
                  agency's expertise and services, providing a top-tier online
                  experience.
                </p>
                {/* <Link to="/case-study"> */}
                <a href="https://www.smartvisionlb.com">
                  <button className="cta project_link">
                    View Website
                    <img
                      src={require("..//assets/images/arrow.png")}
                      alt="arrow"
                    />
                  </button>
                </a>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div className="row project">
            <div className="col-lg-6">
              <div className="project_image">
                <img
                  src={require("..//assets/images/verseDigital.png")}
                  className="project_img"
                  alt="projectImage"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="project_description">
                <h4 className="project_name">Verse Digital</h4>
                <p className="about_project_description">
                  Fully responsive website for a digital agency.
                </p>
                <p className="about_project_description">
                  Built for user-friendly navigation, this website displays the
                  agency's skills and services, ensuring a top-notch online
                  experience.
                </p>
                {/* <Link to="/case-study"> */}
                <a href="https://www.versedigital.media">
                  <button className="cta project_link">
                    View Website
                    <img
                      src={require("..//assets/images/arrow.png")}
                      alt="arrow"
                    />
                  </button>
                </a>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div className="row project">
            <div className="col-lg-6">
              <div className="project_image">
                <img
                  src={require("..//assets/images/powerzone.PNG")}
                  className="project_img"
                  alt="projectImage"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="project_description">
                <h4 className="project_name">PowerZone</h4>
                <p className="about_project_description">
                  Designed & Developed a website that is both fully responsive
                  and user-oriented, ensuring smooth navigation and catchy
                  design.
                </p>
                <p className="about_project_description">
                  This website offers users the ability to effortlessly navigate
                  through a variety of options, streamlining the process of
                  registering for private sessions.
                </p>
                {/* <Link to="/case-study"> */}
                <a href="https://www.powerzone.epizy.com">
                  <button className="cta project_link">
                    View Website
                    <img
                      src={require("..//assets/images/arrow.png")}
                      alt="arrow"
                    />
                  </button>
                </a>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="row project">
            <div className="col-lg-6">
              <div className="project_image">
                <img
                  src={require("..//assets/images/majesticMedia.PNG")}
                  className="project_img"
                  alt="projectImage"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="project_description">
                <h4 className="project_name">Majestic Media</h4>
                <p className="about_project_description">
                  Employing the latest web development techniques to forge a
                  visually captivating, fully responsive, and user-friendly
                  website.
                </p>
                <p className="about_project_description">
                  A design that is both simple and catchy, strategically planned
                  to convert effectively, ensuring a seamless experience for all
                  visitors.
                </p>
                {/* <Link to={{ pathname: "/case-study" }}> */}
                <a href="https://www.Majesticmedia.co">
                  <button className="cta project_link">
                    View Website
                    <img
                      src={require("..//assets/images/arrow.png")}
                      alt="arrow"
                    />
                  </button>
                </a>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div className="row project">
            <div className="col-lg-6">
              <div className="project_image">
                <img
                  src={require("..//assets/images/fabriclean.PNG")}
                  className="project_img"
                  alt="projectImage"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="project_description">
                <h4 className="project_name">FabriClean</h4>
                <p className="about_project_description">
                  Full responsive,user-friendly and easy to navigate website for
                  a cleaning services company based In Lebanon.
                </p>
                <p className="about_project_description">
                  The website presents a comprehensive display of the company
                  portfolio, services, and all the essential information you
                  need to be informed about.
                </p>
                {/* <Link to="/case-study"> */}
                <a href="https://fabricleanlb.co">
                  <button className="cta project_link">
                    View Website
                    <img
                      src={require("..//assets/images/arrow.png")}
                      alt="arrow"
                    />
                  </button>
                </a>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
export default ProjectsSwiper;
