import React from "react";
import { Navigation, Pagination, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Link } from "react-router-dom";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function CaseStudySwiper() {
  return (
    <div className="projects_swiper">
      <Swiper
        spaceBetween={10}
        slidesPerView={3}
        modules={[Navigation, Pagination, A11y]}
        navigation
      >
        <SwiperSlide>
          <div className="studyproject_image">
            <img
              src={require("..//assets/images/majesticMedia.PNG")}
              className="project_img"
              alt="projectImage"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="studyproject_image">
            <img
              src={require("..//assets/images/majesticMedia.PNG")}
              className="project_img"
              alt="projectImage"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="studyproject_image">
            <img
              src={require("..//assets/images/majesticMedia.PNG")}
              className="project_img"
              alt="projectImage"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="studyproject_image">
            <img
              src={require("..//assets/images/majesticMedia.PNG")}
              className="project_img"
              alt="projectImage"
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
export default CaseStudySwiper;
