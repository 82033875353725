import React,{useState} from "react";

function FAQCard(props) {
  const { title, content } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq_card">
      <div className="faq_header" >
        <h5 className="faq_header_title">{title}</h5>
        <div className="faq_img_wrapper" onClick={toggleAccordion}>
        <img
          src={require("..//assets/images/arrow_down.png")}
          alt="Arrow"
          className={`faq_arrow ${isOpen ? "open" : ""}`}
        />
        </div>
      </div>
      {isOpen && <p className="faq_answer">{content}</p>}
    </div>
  );
}

export default FAQCard;
